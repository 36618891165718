.topCard{

    border: 2px solid #F2F3F3;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 20px;
    background-color: white;
    margin: 16px auto 16px;
    max-width: 600px;
    /* box-shadow: 0px 5px 5px -8px; */


}

.flexItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: #f1f1f1f1 2px solid;
  }



.borderedGraphic{
    border-radius: 4px;
    background-color: white;
    /* border-width: 2px;
    border-style: solid;
    border-color:#f1f1f1f1; */
    padding: 8px;
    padding-left: 8px;
    padding-right: 8px;
    height: 100%;
    /* margin-left:8px;
    margin-right:8px; */
  }
  
  .borderedGraphic h4{
    margin: 5px;
    text-align: left;
  }