label{
    color:#666666;
    font-size: 14px;
    text-align: left;
    padding-top:8px;
    padding-bottom:8px;
}

.inputWrap{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 16px;
    margin-left: 0px;
    width:100%;
    max-width: 400px;
}

input, select{
    background-color: #ffffff;
    width: 100%;
    border-style:solid;
    border-color: #F5F5F5 ;
    border-radius: 20px;
    padding: 12px !important;
    font-size: 14px;
    color:#666666;
    border-width: 2px;
    cursor: pointer;
    
}

select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}


