

.header{
	height: 48px !important;
	padding: 10px 16px;
	border-bottom: 1px solid #ebecec;
	background-color: white;
	justify-content: space-between;
	flex-direction: row;
	display: flex;
	align-items: center;
	width: 100%;
}



.headSpace{
	width: 16px;
	height: 24px;
}

.App-logo{

	height: auto;
	width: 100px;

}

.logo{
	font-size: 16px;
	font-weight: 500;
	color: #4A4646;
}



@media only screen and (min-width: 600px) {


	.logo{
		font-size: 24px;
	}

	
	.header{
		height: 72px !important;
		padding: 10px 16px;
	}
	
	.headSpace{
		width: 32px;
		height: 48px;
	}


}



