.SideBarToggle {
    width: 20px;
    height: 20px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    /* padding: 10px 0; */
    box-sizing: border-box;
    cursor: pointer;
    
}

.SideBarToggle div {
    width: 90%;
    height: 3px;
    background-color: #303030;
    border-radius: 2px;
}

@media (min-width: 600px) {
    .SideBarToggle {
        width: 28px;
        height: 28px;
    }

    .SideBarToggle div {
        height: 3px;
    }
}