.cardHeader{
    background-color: #4470B3;
    color: white;
    border-radius: 0px 0px 20px 20px;
    border: none;
    opacity: 0.9;
    padding: 8px;


}

.cardHeaderDark{
    background-color: #303030;
    color: white;
    border-radius: 20px 20px 0px 0px;
    border: none;
    opacity: 0.9;
    padding: 8px;

}


.cardHeaderSec{
    background-color: #4470B3;
    color: white;
    border-radius: 20px 20px 0px 0px;
    border: none;
    opacity: 0.9;
    padding: 8px;

}

.blingSecUnder{
    border-bottom:1px solid #4470B3;
    margin: 0px auto 16px;
    width: 25%;
    max-width: 200px;

}
