
.iconHead{

    max-width: 500px;
    margin: 16px auto 0px;
    padding: 32px 16px 32px;

}

@media only screen and (min-width: 600px) {

    .iconHead{

        padding: 40px 24px 40px;
    
    }

}