.expanded p,.expanded h3, .expandedSec h3, .expandedSec p{
    color: white;
  
  }


  
  .chevron{
    height:30px;
    margin-bottom: 8px;
  }
  
  .heightZero{
    height: 0px;
  }
  
  .expanding{
    -webkit-transition: all 5.5s ease-out;
    -moz-transition: all 5.5s ease-out;
    -o-transition: all 5.5s ease-out;
    transition: all 5.5s ease-out;
  }

  .expanded{
    color: white;
    background-color:  #303030;
    border-radius: 20px 20px 0px 0px;
    border: none;
    opacity: 0.9;
    position: relative;
    /* -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out; */
  
  }

  .unexpanded{
    position: relative;
  }

  .expandedSec{
    color: white;
    background-color: #4470B3;
    border-radius: 20px 20px 0px 0px;
    border: none;
    /* -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out; */
  
  }



  .expandableHeader{
    height: 100%;
    position: relative;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 4px;
    position: relative;
    cursor: pointer;
  }

  .expandAction{
    border-radius: 100%;
    background-color: #feb116;
    height: 33px;
    width: 33px;
    opacity:0.8;

  }

  .expandLeftSide{
    background-color: #303030;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    width: 20%;
    min-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0px;
    position: absolute;
    left:0;
    
  }

  .expandLeftSideSec{
    background-color: #4470B3;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    width: 20%;
    min-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0px;
    position: absolute;
    left:0;
  }

  .expandLeftSide > p {
    font-size: 20px;
    color: white;
    text-shadow: 0 0 3px rgba(0,0,0,0.50);
    margin:0;
  }

  .expandTitle{
    height: 100%;
    width: 60%;
    
  }

  .expandRightSide{
    height: 100%;
    width: 20%;
    position: absolute;
    right:0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

.Opachundred{
  opacity: 1;
}

.Opacninety{
  opacity: 0.9;
}

.Opaceighty{
  opacity: 0.8;
}

.Opacseventy{
  opacity: 0.7;
}

.Opacsixty{
  opacity: 0.6;
}

.Opacfifty{
  opacity: 0.5;
}

.Opacfifty{
  opacity: 0.4;
}

.expanded .Opacfifty,.expanded .Opacsixty,.expanded .Opacseventy,.expanded .Opaceighty,.expanded .Opacninety{
  opacity: 1;
}