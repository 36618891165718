.nav ul li {
    list-style: none;
    padding: 4px 4px;
    cursor: pointer;
    border-bottom: 1px solid #ebecec;
    
}

.nav ul{
    list-style: none;
    padding: 0;
}

.nav ul li:hover{
    background-color: #FEB116;
}

.nav ul li:hover p{
    color: white;
    list-style: none;
}

.wHov{
    /* border: #364B94 2px solid; */
    width: 100%;
    height: 100%;
    display:flex;;
    flex-direction: row;
    align-items: center;
    padding-left:16px;

    height:48px;
}

