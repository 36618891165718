

textarea{
    background-color: #ffffff;
    width: 100%;
    border-style:solid;
    border-color: #F5F5F5 ;
    border-radius: 4px;
    padding: 12px;
    font-size: 14px;
    color:#666666;
    border-width: 2px;
    min-height: 100px
    
}