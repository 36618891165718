
/*   Home */

.homeWrap{
    background-color: #303030;
    min-height: 320px;
    height: 80vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh) * 80);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    width: 100%;
    opacity: 0.99;
    position: absolute;
            right: 0;
            top: 0;
  }
  
  .homeWrap h1{
    margin: 0;
  }
  
  .threeColWrap{
    padding-bottom: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
  }
  
  .threeCol{
    background-color: #FCFCFC;
    border-radius: 20px;
    width:90%;
    margin: 16px auto 16px;
    box-shadow: 0px 5px 5px -8px;
    
  }
  
  .homeText{
    color: white;
    font-size: 40px;
  }
  
  .homeContainer{
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 30px 16px 0px;
    position: relative;
    opacity: 1;
  }

  .homeCover::before {
    background: linear-gradient(rgba(48, 48, 48, 0.45), rgba(48, 48, 48, 0.45)), url(../../images/homemob.jpg);
    content: "";
    display: block;
    position: absolute;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    opacity: 0.2;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    }
  


