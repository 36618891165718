.SideBar {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    /* padding: 32px 16px; */
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.sideHeader{
        height: 72px !important;
        padding: 10px 16px;
        border-bottom: 1px solid #303030;
        background-color: #303030;
        border-top-right-radius: 40px;

}
/* @media (min-width: 500px) {
    .SideBar {
        display: none;
    }
} */

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}

.Logo {
    height: 11%;
    margin-bottom: 32px;
}