.bannerTitle{

    font-weight: 700;
    font-size: 33px;
    margin-bottom: 16px;
    color: white;

}

.bannerSecTitle{
  font-weight: 400;
  font-size: 20px;
  color: white;

}

.bannerUnderline{
    width: 33%;
    max-width: 200px;
    border-bottom: 1px solid white;
    margin: 0px auto 0px;
    
}

.bannerText{

    font-weight: 400;
    font-size: 18px;
    margin-bottom: 24px;
    color: #feb116;

}



.banner-content{
    max-width: 500px;
    margin: 16px auto 0px;
    padding: 40px 16px 40px;
}
  
  
  
  


@media only screen and (min-width: 600px) {


    .bannerTitle{
        font-size: 40px;
      }

  .bannerText{
    font-size: 18px;
    /* margin: 24px 0px 24px 0px */
  }

}





  @media only screen and (min-width: 900px) {





  .bannerTitle{

    font-size:48px

  }


  .bannerText{
    font-size: 18px;
    /* margin: 24px 0px 24px 0px */
  }

}


@media only screen and (min-width: 1080px) {



}

  