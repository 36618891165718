.numSelectList{
    list-style: none;
    margin:0;
    padding: 0;
    /* width: 100%; */
    margin-top:16px;

    

}

.numSelectList li{
    display: inline-block;

}



.numSelectContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .numSelectContainer {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 36px;
    height:36px;
    margin: 0px;
    margin-left: 4px;
    padding: 0;
    border-width: 0;
    border-style: solid;
    border-color: white;
    border-radius: 4px;

  }


  .numSelectContainer .numSelectmark:after {
    /* left: 9px;
    top: 9px;
    width: 8px;
    height: 10px; */
    /* border: solid #666666; */
    /* border-radius: 50%; */
  }

  .numSelectmark {
    position: absolute;
    top: -2px;
    left: -2px;
    height: 40px;
    width: 40px;
    background-color: #f5f5f5;
    border-width: 2px;
    border-style: solid;
    border-color: white;
    border-radius: 4px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
  }

  .numSelectContainer:hover input ~ .numSelectmark {
    /* background-color: #FEB116;; */
  }

  .numSelectContainer input:checked ~ .numSelectmark {
    background-color: #FEB116;;
  }

  .numSelectmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the numSelectmark when numSelected */
.numSelectContainer input:checked ~ .numSelectmark:after {
    display: block;
  }