/*   Custom Variables */

:root {
  --vh: 1vh;
  --color-solid: black;
  --color-primary: teal;
}


/*   Structural */




.App {
  text-align: center;
  position: relative;
  min-height: 100vh;
}

.container {
  padding-bottom: 60px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
          "content"
          "sidebar"
}

.inline{
  display: inline-block;
}

.containerSingle {
  padding-bottom: 60px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
          "content"
}



.content-area {
  grid-area: content;
}

.aside {
  grid-area: sidebar;
}







.content{
  width:100%;
  padding-bottom: 60px;
}

.contentWrap{
    max-width: 560px;
    margin: 32px auto 32px
}

.widthControl{
  max-width: 560px;
  margin: 0px auto 0px
}

.scoreImageWrap{
  width: 200px;
  margin:0px auto 0px;
}




/*Grid*/

.third{
  width:33%;

}

.twothird{
  width:66%;

}

/*Splits*/


.ten{
  width:10%;
}





.eighty{
  width:80%;

}

.fteen{
  width:15%;
}

.twen{
  width:20%;
}

.fifty{
  width: 50%;

}

.sixty{
  width: 60%;

}

.forty{
  width: 40%;

}





.standardPadding{
  padding: 16px 24px;

}

.fixedPadding{
  padding: 16px 32px;

}

.flexWrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.flexWrapCol{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.flexWrapColL{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.flexWrapSpaceBet{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: space-between;
  position: relative;
}

.flexWrapStart{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: space-between;
  position: relative;
}

.card{
  background-color: #FCFCFC;
  border-radius: 20px;
  width:90%;
  margin: 16px auto;
  box-shadow: 0px 5px 5px -8px;
}

.cardFoot{
  background-color: #4470B3;
  border-radius: 0px 0px 20px 20px;
  padding: 32px;
  margin-top: 16px;
  color: white;
}

/* .adviceCard{
  height: 154px;

}

.adviceCard > h3 {
  margin-top: 8px;
  margin-bottom: 8px;
} */

.smallIcon{
  max-height: 50px;
  width:50px;
}




.resultSummary{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.vertPad{
  padding: 0px 0 16px 0;
}

.hide{
  display: none;
}

.espace{
  margin-top:32px;
}

.espacebot{
  margin-bottom:32px;
}

.doubleSpace{
  margin-top:16px;
  margin-bottom: 32px;
}

.lMar{
  padding-left: 4px;
}

.lightPadding{
  padding-left: 8px;
  padding-right: 8px;
}

.canvasOverlay{

    position:absolute;
    width: 100%;
    height: 100%;
    left:0;
    top: 0;
    opacity:0;
    background-color: white;

}

.uBordLine{
  border-bottom: 1px solid #F2F3F3;

}


.largeNumber{
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  margin: 0px 0px;

}


/*   Banner */


.banner{
  background: #303030;
  margin: 0;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
  opacity: 0.99;
  width: 100%;

}


.coins::before {
  background-image: url(./images/coinsmob.jpg);
  background-size: cover;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  opacity: 0.3;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  }

  .retire::before {
    background-image: url(./images/retiremob.jpg);
    background-size: cover;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    opacity: 0.3;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    }


    .advice::before {
      background-image: url(./images/advicemob.jpg);
      background-size: cover;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      opacity: 0.3;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      }


  .protect::before {
    background-image: url(./images/safemob.jpg);
    background-size: cover;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    opacity: 0.2;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    }


    .question::before {
      background-image: url(./images/questionmob.jpg);
      background-size: cover;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      opacity: 0.2;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      }









.letSpace{
  padding-right: 16px;
}

.clockImage, .calenderImage{
  padding-left: 4px;
  /* min-width: 8px; */
}

.flexWrap li, .flexWrapRight li{
  list-style: none;
}



.pointer{
  cursor: pointer;
}



/*     Button     */


.actionButton{
  margin-top:16px;
  margin-bottom:16px;
  border-radius: 20px;
  background-color: #FEB116;
  color:white;
  width:100%;
  height:48px;
  box-shadow: none;
  border-style: hidden;
  cursor: pointer;
  font-size: 14px;
  max-width: 400px;
  cursor: pointer;
}

.actionButtonSec{
  margin-top:16px;
  margin-bottom:16px;
  border-radius: 20px;
  background-color: #4470B3;
  color:white;
  width:100%;
  height:48px;
  box-shadow: none;
  border-style: hidden;
  cursor: pointer;
  font-size: 14px;
  max-width: 400px;
  cursor: pointer;
}

.actionButton:hover{
  background-color: #FEA116;

}

.actionButtonS{
  margin-top:8px;
  margin-bottom:8px;
  border-radius: 20px;
  background-color: white;
  color:#FEB116;
  width:100px;
  height:32px;
  box-shadow: none;
  /* border-style: hidden; */
  border: 1px solid #FEB116;
  cursor: pointer;
}

.actionButtonS:hover{
  background-color: #FEA116;
  color: white;
}

.wide{
  max-width: 400px;
  width:100%;
  height:48px;
}

.fixedFab{
  border-radius: 100%;
  border: 0px solid;
  font-size: 24px;
  width: 50px;
  height: 50px;
  position: fixed !important;
  right: 15px;
  bottom: 15px;
  z-index: 1;
  background-color: #FEB116;
  color: #ffffff;
  vertical-align: middle;
}



/*     Wraps     */

.graphWrap{
  max-width: 400px;
  margin: auto;
  position: relative;
}

.quoteWrap{
  margin-bottom: 32px;
}

.quoteText{
  font-size: 16px;
}







.App-logo {
  max-width: 100px;
  
}




.cardClick{
  background-color: #FCFCFC;
  border-radius: 4px;
  width:90%;
  margin: 8px auto;
}

.cardClick:hover {
  background-color: #303030;
}

.cardClick:hover > p{
  color: white;
}




/*   Typography */

h1,h2,h3,h4,h5,h6, p,a, ol, ul {
  color: #666666;

}


h1{
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 24px
}

h2, h5{
  font-size: 18px;
  font-weight: 400;

}

h3{
  font-size: 18px;
  font-weight: 400;
}

h4{
  font-size: 16px;
  font-weight: 400;
}

h6{
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 16px;

}

.subHead{
  font-size: 16px;
  text-align: center;
  margin: 0px;
}

.introText{
  font-size: 18px;
}

p, ol, ul{
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  text-align: left;
}

.sText{
  font-size: 11px;

}

.card li{
  margin-bottom: 8px;
}

.bling{
  color: #FEB116;
  font-weight: 700;
}

.blingSec{
  color: #4470B3;
  font-weight: 700;
}

.warningText{
  color: #B43C26;
  font-weight: 700;
}

.b{
  font-weight: 700;
}

.s{
  font-size: 10px;
}

.i{
  font-style: italic;
}

.subHead{
  font-size: 16px;
  text-align: center;
  margin: 0px;
}



.white{
  color: white;
}

.secWhite{
  color: #D8D8D8;
}

.formStyle{
  max-width: 400px;
  margin: 0px auto;
}



/* .bling h4{
  color: #FEB116;
  font-weight: 700;
} */


.u_l{
  text-decoration: underline;
}

a{
  text-decoration: none;
}


/*   Alignment */

.centre{
  text-align: center;
}

.left{
  text-align: left;
}








/*   Button   */

.iconWrap{
  height:16px;
  width: 16px;
}

.iconButtonWrap{
  padding:8px;
  cursor: pointer;
}

.iconMedalWrap{
  margin-left:16px;
}

.nMar > p, .nMar > h4{
  margin:0;
}


/*   Over and Underlined   */

.uL {
  padding-bottom: 12px;
  border-bottom: #f2f2f2 2px solid;
}

.oL {
padding-top: 8px;
margin-top: 8px;
border-top: #f2f2f2 2px solid;
}




/*   Lists   */



ol, ul {
  list-style: none; /* Remove default bullets */
  margin: 0px;
  padding: 16px;
}


ol li::before {
  content: "\2022";  
  color: #FEB116;; 
  font-weight: bold; 
  font-size: 16px;
  display: inline-block; 
  width: 1em; 
  margin-left: -1em; 
}


/*   Pictures  */

.pictureWrap{
  width: 100%;
  margin-top:16px;
  margin-bottom:16px;


}


/****************            animation classes    ***************/

.animate-pop-in {
  -webkit-animation: no-transform .8s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
          animation: no-transform .8s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
  opacity: 0;
  -webkit-transform: translateY(-4rem) scale(.8);
          transform: translateY(-4rem) scale(.8);
}

.animate-fade-in {
  -webkit-animation: fadein 2s forwards;
          animation: fadein 2s forwards;
          opacity: 0;
}

.animate-pulse {
  -webkit-animation: pulse 1.5s 1.5s ease-out forwards;
          animation: pulse 1.5s 1.5s ease-out forwards;
          opacity: 0;
}





  

.anim-delay-first {
  -webkit-animation-delay: .2s;
          animation-delay: .2s;
}

.anim-delay-second{
  -webkit-animation-delay:.4s;
          animation-delay:.4s;
}

.anim-delay-third{
  -webkit-animation-delay: .6s;
          animation-delay: .6s;
}

.anim-delay-fourth {
  -webkit-animation-delay: .8s;
          animation-delay: .8s;
}

.anim-delay-fifth {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.anim-delay-sixth{
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

.anim-delay-seventh{
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}
.anim-delay-eighth{
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s;
}

.anim-delay-ninth{
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}

.anim-delay-long{
  -webkit-animation-delay: 2.2s;
          animation-delay: 2.2s;
}








@media only screen and (min-width: 600px) {

  .content{
    max-width: 80%;
    margin-left: 10%;
    text-align: center;
  }

  .container{
    max-width: 80%;
    margin-left: 10%;
  }

  .containerSingle{
    max-width: 80%;
    margin-left: 10%;
  }

  .card{
    width:100%;
  }


  .protect::before {
    background-image: url(./images/safepad.jpg);
    border-radius: 0px;
  }

  .retire::before {
    background-image: url(./images/retirepad.jpg);
    border-radius: 0px;
  }

  .coins::before {
    background-image: url(./images/coinsdesktop.jpg);
    border-radius: 0px;

  }

  .homeCover::before {
    background-image: url(./images/homepad.jpg);
    border-radius: 0px;

  }

  .advice::before {
    background-image: url(./images/advicepad.jpg);
    border-radius: 0px;

  }


  




  .quoteText{
    font-size: 18px;
  }

  h1{
    font-size: 19px;
    font-weight: 400;
    margin-bottom: 24px
  }
  
  h2, h5{
    font-size: 19px;
    font-weight: 400;
  
  }
  
  h3{
    font-size: 20px;
    font-weight: 400;
  }
  
  h4{
    font-size: 18px;

  }

  h5{
    margin-top: 16px;
    margin-bottom: 16px;
  }
  
  h6{
    font-size: 18px;
    font-weight: 400;
  
  }
  
  .subHead{
    font-size: 16px;
    text-align: center;
    margin: 0px;
  }
  
  p, ol, ul{
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
  }
  


  .homeText{
    color: white;
    font-size: 56px;
  }
  
  .letSpace{
    padding-right: 20px;
  }

  .pictureWrap{
    width: 80%;
    margin-left: 10%;
    margin-top:16px;
    margin-bottom:16px;

  
  }

  .banner{
    background: #303030;
    margin: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    min-height: 360px;
    position: relative;
    border-radius: 0px;

    
  }




  .standardPadding{
    padding: 16px 24px;
  
  }

  .lightPadding{
    padding-left: 24px;
    padding-right: 24px;
  }

}







@media only screen and (min-width: 900px) {
  .content{
    max-width: 60%;
    margin-left: 20%;
    text-align: center;
  }

  .containerSingle{
    max-width: 60%;
    margin-left: 20%;
  }


  .retire::before {
    background-image: url(./images/retiredesktop.jpg);
    
  }

  .protect::before {
    background-image: url(./images/safedesktop.jpg);
    border-radius: 0px;

  }

  .coins::before {
    background-image: url(./images/coinsdesktop.jpg);
    border-radius: 0px;

  }

  .homeCover::before {
    background-image: url(./images/homedesktop.jpg);
    border-radius: 0px;

  }

  .advice::before {
    background-image: url(./images/advicedesktop.jpg);
    border-radius: 0px;

  }




  .quoteText{
    font-size: 18px;
  }

  .banner{
    background: #303030;
    margin: 0;
    padding-top: 48px;
    padding-bottom: 48px;
    text-align: center;
    min-height: 400px;
    
  
  }

  .homeText{
    color: white;
    font-size: 62px;
  }
  
  .letSpace{
    padding-right: 24px;
  }

  .pictureWrap{
    width: 70%;
    margin-left: 15%;
    margin-top:24px;
    margin-bottom:24px;

  
  }

  .standardPadding{
    padding: 24px 32px;
  
  }

  .lightPadding{
    padding-left: 32px;
    padding-right: 32px;
  }

  .threeCol{
    width:31%;
  }

}

@media only screen and (min-width: 1080px) {
  .content{
    max-width: 50%;
    margin-left: 25%;
    text-align: center;
  }

  .container {
    display: grid;
     grid-template-columns: 2fr 6fr 4fr;
     grid-gap: 2rem 1rem;
    grid-template-areas:
            "content content sidebar"
  }



  .threeCol{
    width:31%;
  }

}



@keyframes no-transform {
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@-webkit-keyframes fade-slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-4rem);
            transform: translateY(-4rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes fade-slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-4rem);
            transform: translateY(-4rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@-webkit-keyframes pop-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-4rem) scale(.8);
            transform: translateY(-4rem) scale(.8);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes pop-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-4rem) scale(.8);
            transform: translateY(-4rem) scale(.8);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}


@-webkit-keyframes fadein{
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


@-webkit-keyframes pulse {
  0% {
    opacity: .2;
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
  }
  50% {
    opacity: .8;
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes pulse {
  0% {
    opacity: .5;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}


@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-4rem) scale(.8);
            transform: translateY(-4rem) scale(.8);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes slide-down {
  0% {
    opacity: 1;
    -webkit-transform: scaleY(0); 
            transform: scaleY(0); 
            transform-origin:top;
            height:0;
  }
  100% {
    opacity: 1;
    -webkit-transform:scaleY(1); 
            transform:scaleY(1); 
            height:auto;
            transform-origin:top;
  }
}



